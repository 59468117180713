exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-ar-js": () => import("./../../../src/pages/about-us.ar.js" /* webpackChunkName: "component---src-pages-about-us-ar-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-advisory-research-ar-js": () => import("./../../../src/pages/advisory-research.ar.js" /* webpackChunkName: "component---src-pages-advisory-research-ar-js" */),
  "component---src-pages-advisory-research-en-js": () => import("./../../../src/pages/advisory-research.en.js" /* webpackChunkName: "component---src-pages-advisory-research-en-js" */),
  "component---src-pages-advisory-research-js": () => import("./../../../src/pages/advisory-research.js" /* webpackChunkName: "component---src-pages-advisory-research-js" */),
  "component---src-pages-announcements-ar-js": () => import("./../../../src/pages/announcements.ar.js" /* webpackChunkName: "component---src-pages-announcements-ar-js" */),
  "component---src-pages-announcements-en-js": () => import("./../../../src/pages/announcements.en.js" /* webpackChunkName: "component---src-pages-announcements-en-js" */),
  "component---src-pages-announcements-jsx": () => import("./../../../src/pages/announcements.jsx" /* webpackChunkName: "component---src-pages-announcements-jsx" */),
  "component---src-pages-asset-management-ar-js": () => import("./../../../src/pages/asset-management.ar.js" /* webpackChunkName: "component---src-pages-asset-management-ar-js" */),
  "component---src-pages-asset-management-en-js": () => import("./../../../src/pages/asset-management.en.js" /* webpackChunkName: "component---src-pages-asset-management-en-js" */),
  "component---src-pages-asset-management-js": () => import("./../../../src/pages/asset-management.js" /* webpackChunkName: "component---src-pages-asset-management-js" */),
  "component---src-pages-board-ar-js": () => import("./../../../src/pages/board.ar.js" /* webpackChunkName: "component---src-pages-board-ar-js" */),
  "component---src-pages-board-directors-ar-js": () => import("./../../../src/pages/board-directors.ar.js" /* webpackChunkName: "component---src-pages-board-directors-ar-js" */),
  "component---src-pages-board-directors-en-js": () => import("./../../../src/pages/board-directors.en.js" /* webpackChunkName: "component---src-pages-board-directors-en-js" */),
  "component---src-pages-board-directors-js": () => import("./../../../src/pages/board-directors.js" /* webpackChunkName: "component---src-pages-board-directors-js" */),
  "component---src-pages-board-en-js": () => import("./../../../src/pages/board.en.js" /* webpackChunkName: "component---src-pages-board-en-js" */),
  "component---src-pages-board-js": () => import("./../../../src/pages/board.js" /* webpackChunkName: "component---src-pages-board-js" */),
  "component---src-pages-client-awareness-ar-js": () => import("./../../../src/pages/client-awareness.ar.js" /* webpackChunkName: "component---src-pages-client-awareness-ar-js" */),
  "component---src-pages-client-awareness-en-js": () => import("./../../../src/pages/client-awareness.en.js" /* webpackChunkName: "component---src-pages-client-awareness-en-js" */),
  "component---src-pages-client-awareness-js": () => import("./../../../src/pages/client-awareness.js" /* webpackChunkName: "component---src-pages-client-awareness-js" */),
  "component---src-pages-companies-login-ar-js": () => import("./../../../src/pages/companies-login.ar.js" /* webpackChunkName: "component---src-pages-companies-login-ar-js" */),
  "component---src-pages-companies-login-en-js": () => import("./../../../src/pages/companies-login.en.js" /* webpackChunkName: "component---src-pages-companies-login-en-js" */),
  "component---src-pages-companies-login-js": () => import("./../../../src/pages/companies-login.js" /* webpackChunkName: "component---src-pages-companies-login-js" */),
  "component---src-pages-conservation-services-ar-js": () => import("./../../../src/pages/conservation-services.ar.js" /* webpackChunkName: "component---src-pages-conservation-services-ar-js" */),
  "component---src-pages-conservation-services-en-js": () => import("./../../../src/pages/conservation-services.en.js" /* webpackChunkName: "component---src-pages-conservation-services-en-js" */),
  "component---src-pages-conservation-services-js": () => import("./../../../src/pages/conservation-services.js" /* webpackChunkName: "component---src-pages-conservation-services-js" */),
  "component---src-pages-contact-us-ar-js": () => import("./../../../src/pages/contact-us.ar.js" /* webpackChunkName: "component---src-pages-contact-us-ar-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../../../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-advisory-research-js": () => import("./../../../src/pages/en/advisory-research.js" /* webpackChunkName: "component---src-pages-en-advisory-research-js" */),
  "component---src-pages-en-announcements-js": () => import("./../../../src/pages/en/announcements.js" /* webpackChunkName: "component---src-pages-en-announcements-js" */),
  "component---src-pages-en-asset-management-js": () => import("./../../../src/pages/en/asset-management.js" /* webpackChunkName: "component---src-pages-en-asset-management-js" */),
  "component---src-pages-en-board-directors-js": () => import("./../../../src/pages/en/board-directors.js" /* webpackChunkName: "component---src-pages-en-board-directors-js" */),
  "component---src-pages-en-board-js": () => import("./../../../src/pages/en/board.js" /* webpackChunkName: "component---src-pages-en-board-js" */),
  "component---src-pages-en-client-awareness-js": () => import("./../../../src/pages/en/client-awareness.js" /* webpackChunkName: "component---src-pages-en-client-awareness-js" */),
  "component---src-pages-en-companies-login-js": () => import("./../../../src/pages/en/companies-login.js" /* webpackChunkName: "component---src-pages-en-companies-login-js" */),
  "component---src-pages-en-conservation-services-js": () => import("./../../../src/pages/en/conservation-services.js" /* webpackChunkName: "component---src-pages-en-conservation-services-js" */),
  "component---src-pages-en-contact-us-js": () => import("./../../../src/pages/en/contact-us.js" /* webpackChunkName: "component---src-pages-en-contact-us-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-individuals-login-js": () => import("./../../../src/pages/en/Individuals-login.js" /* webpackChunkName: "component---src-pages-en-individuals-login-js" */),
  "component---src-pages-en-investment-banking-js": () => import("./../../../src/pages/en/Investment-banking.js" /* webpackChunkName: "component---src-pages-en-investment-banking-js" */),
  "component---src-pages-en-management-team-js": () => import("./../../../src/pages/en/management-team.js" /* webpackChunkName: "component---src-pages-en-management-team-js" */),
  "component---src-pages-en-management-team-templet-js": () => import("./../../../src/pages/en/managementTeamTemplet.js" /* webpackChunkName: "component---src-pages-en-management-team-templet-js" */),
  "component---src-pages-en-reports-js": () => import("./../../../src/pages/en/reports.js" /* webpackChunkName: "component---src-pages-en-reports-js" */),
  "component---src-pages-en-shariyah-review-js": () => import("./../../../src/pages/en/shariyah-review.js" /* webpackChunkName: "component---src-pages-en-shariyah-review-js" */),
  "component---src-pages-index-ar-js": () => import("./../../../src/pages/index.ar.js" /* webpackChunkName: "component---src-pages-index-ar-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individuals-login-ar-js": () => import("./../../../src/pages/Individuals-login.ar.js" /* webpackChunkName: "component---src-pages-individuals-login-ar-js" */),
  "component---src-pages-individuals-login-en-js": () => import("./../../../src/pages/Individuals-login.en.js" /* webpackChunkName: "component---src-pages-individuals-login-en-js" */),
  "component---src-pages-individuals-login-js": () => import("./../../../src/pages/Individuals-login.js" /* webpackChunkName: "component---src-pages-individuals-login-js" */),
  "component---src-pages-investment-banking-ar-js": () => import("./../../../src/pages/Investment-banking.ar.js" /* webpackChunkName: "component---src-pages-investment-banking-ar-js" */),
  "component---src-pages-investment-banking-en-js": () => import("./../../../src/pages/Investment-banking.en.js" /* webpackChunkName: "component---src-pages-investment-banking-en-js" */),
  "component---src-pages-investment-banking-js": () => import("./../../../src/pages/Investment-banking.js" /* webpackChunkName: "component---src-pages-investment-banking-js" */),
  "component---src-pages-management-team-ar-js": () => import("./../../../src/pages/management-team.ar.js" /* webpackChunkName: "component---src-pages-management-team-ar-js" */),
  "component---src-pages-management-team-en-js": () => import("./../../../src/pages/management-team.en.js" /* webpackChunkName: "component---src-pages-management-team-en-js" */),
  "component---src-pages-management-team-jsx": () => import("./../../../src/pages/management-team.jsx" /* webpackChunkName: "component---src-pages-management-team-jsx" */),
  "component---src-pages-management-team-templet-ar-js": () => import("./../../../src/pages/managementTeamTemplet.ar.js" /* webpackChunkName: "component---src-pages-management-team-templet-ar-js" */),
  "component---src-pages-management-team-templet-en-js": () => import("./../../../src/pages/managementTeamTemplet.en.js" /* webpackChunkName: "component---src-pages-management-team-templet-en-js" */),
  "component---src-pages-management-team-templet-js": () => import("./../../../src/pages/managementTeamTemplet.js" /* webpackChunkName: "component---src-pages-management-team-templet-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-reports-ar-js": () => import("./../../../src/pages/reports.ar.js" /* webpackChunkName: "component---src-pages-reports-ar-js" */),
  "component---src-pages-reports-en-js": () => import("./../../../src/pages/reports.en.js" /* webpackChunkName: "component---src-pages-reports-en-js" */),
  "component---src-pages-reports-jsx": () => import("./../../../src/pages/reports.jsx" /* webpackChunkName: "component---src-pages-reports-jsx" */),
  "component---src-pages-shariyah-review-ar-js": () => import("./../../../src/pages/shariyah-review.ar.js" /* webpackChunkName: "component---src-pages-shariyah-review-ar-js" */),
  "component---src-pages-shariyah-review-en-js": () => import("./../../../src/pages/shariyah-review.en.js" /* webpackChunkName: "component---src-pages-shariyah-review-en-js" */),
  "component---src-pages-shariyah-review-js": () => import("./../../../src/pages/shariyah-review.js" /* webpackChunkName: "component---src-pages-shariyah-review-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

